.title {
    margin-block: 2rem;
    font-weight: bold;
    font-size: 26px;
}

.documentName {
    margin-block: 1rem;
    padding-top: 30px;
    border-top: 1px solid #efeced;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
}