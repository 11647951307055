@import '/src/Components/00.base/styles/variables';

.compactContainer {
    cursor: pointer;

    .titleText {
        font-size: 18px;
    }
}

.container {
    margin-bottom: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 20px 10px;
    margin-block: 15px;
    //cursor: pointer;
}

.input {
    width: 80%;
}

.title {
    position: relative;
    display: flex;
    //justify-content: space-between;

    .titleText {
        max-width: 87%;
        font-weight: 400;
        font-size: $fs-xl;
    }

    .addButton {
        width: 18%;
        display: flex;
        justify-content: end;

    }

    svg {
        // position: absolute;
        // right: 10px;
        // top: -15px;
        // bottom: 0;
        // margin: auto;
        cursor: pointer;
    }

    .closed {
        svg {
            transform: rotate(0deg);
        }
    }

    .opened {
        svg {
            transform: rotate(180deg);
        }
    }
}

.description {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    font-size: 1.6rem;
}

.content {
    margin-top: 20px;

    h4 {
        margin-bottom: 30px;
    }
}

.accordionHeader {
    position: absolute;
    top: 15px;
    right: 0;
    bottom: 0;
}

.headerButton {
    svg {
        top: -15px;
        bottom: 0;
        right: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .title {
        .titleText {
            max-width: 75%;
        }
    }
}