@import '/src/Components/00.base/styles/variables';

.titleInnerWrap {
  display: flex;
  gap: 10px;
}

.titleIconWrap {
  svg.list {
    color: $primary-icon;
    cursor: pointer;
  }

  svg.fileIcon {
    color: $nile-blue;
  }
}

.titleWrap {
  h3 {
    padding-bottom: 0;
    text-transform: capitalize;
    font-size: $fs-normal;
  }

  p {
    color: $input-text-disabled;
    font-size: $fs-sm;
  }
}

.titleWithIcon {
  display: flex;
  align-items: center;
}